import { IState } from '@pvolve/sdk/src/redux/selectors';
import React, { useState } from 'react';
import Actions from 'src/state/root-actions';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Grid } from 'semantic-ui-react';
import { Modal } from 'src/components/shared';

import SEO from 'src/components/SEO';
import Selectors from 'src/state/root-selectors';

const connector = connect(
    (state: IState) => ({
        user: Selectors.auth.user(state),
    }),
    {
        refresh: () => Actions.auth.refresh.trigger(),
    }
);

const IndexPage = ({ user, refresh }: ConnectedProps<typeof connector>) => {
    const [modalOptions, setModalOptions] = useState({ open: false });

    const showModal = (options) => () => {
        setModalOptions({
            open: true,
            ...options,
        });
    };

    const killModal = () => {
        setModalOptions({ open: false });
    };

    const button = ({ label, ...options }, index) => (
        <Button key={index} onClick={showModal(options)}>
            {label}
        </Button>
    );

    const modals = [
        {
            label: 'Simple Modal',
            text: 'A simple modal without actions.',
        },
        {
            label: 'Children',
            children: (
                <p className="p3">
                    Modal with a couple buttons, children, a close "x", and can do <b>this</b>
                </p>
            ),
            closeButton: true,
            actions: [{ label: 'Cancel' }, { label: 'Ok', secondary: true }],
        },
        {
            label: 'Buttons!',
            text: 'Modal with the max of 4 buttons',
            actions: [
                { label: 'Cancel' },
                { label: 'Maybe' },
                { label: 'Ok' },
                { label: 'Alright fine!', secondary: true },
            ],
        },
    ];

    return (
        <>
            <SEO title="Pvolve" />
            <Grid container style={{ margin: '5rem 0' }}>
                <Grid.Column width={3} />
                <Grid.Column width={4} verticalAlign="middle">
                    <h1>
                        Hi {(user?.given_name && user?.given_name !== 'First_Name') || 'people'}!
                    </h1>
                    <Button onClick={refresh}>Token Refresh</Button>
                </Grid.Column>
                <Grid.Column width={7} verticalAlign="middle">
                    {modals.map(button)}
                </Grid.Column>
                <Grid.Column width={2} />
            </Grid>
            <Modal onClose={killModal} {...modalOptions} />
        </>
    );
};

export default connector(IndexPage);
